<template>
  <div class="record">
    <div class="wrap">
      <div class="rr-title">
        <img src="../../public/images/asset/back.png" @click="$router.go(-1)" />
        {{ detailInfo.coinName }}
      </div>
      <div class="wrap-count animate__animated animate__bounceInUp">
        <div class="num">{{ detailInfo.totalAmount }}</div>
        <span>≈{{ detailInfo.totalUSDTAmount }} USDT</span>
      </div>
      <div class="wrap-til animate__animated animate__bounceInUp">交易记录</div>
      <div class="rr-h5-time-out animate__animated animate__bounceInUp">
        <div class="rr-h5-time">
          <img src="../../public/images/asset/time-icon.png" />
          <div class="item" @click="startShow = true">
            <input
              type="text"
              v-model="startTime"
              placeholder="开始时间"
              disabled
            />
          </div>
          <span>至</span>
          <div class="item" @click="endShow = true">
            <input
              type="text"
              v-model="endTime"
              placeholder="结束时间"
              disabled
            />
          </div>
        </div>
      </div>
      <div class="rr-tabbar flex animate__animated animate__bounceInUp">
        <div class="rr-select">
          <div class="rr-type">类型</div>
          <p>
            <el-select
              v-model="typeName"
              :placeholder="$t('planet.desc1')"
              @change="selectType"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, i) in typeList"
                :key="i"
              ></el-option>
            </el-select>
          </p>
        </div>
        <div class="rr-select rr-select-time">
          <div class="rr-type">时间</div>
          <p>
            <el-date-picker
              @change="dateSelect"
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </p>
        </div>
      </div>
      <div
        class="rr-wrap animate__animated animate__bounceInUp"
        v-if="recordList && recordList.length > 0"
      >
        <div class="rr-wrap-h5">
          <div
            class="item"
            v-for="(item, i) in recordList"
            :key="i"
            @click="goDetail(item)"
          >
            <img
              class="detail-go"
              src="../../public/images/asset/detail-go.png"
              alt=""
            />
            <div class="item-l">
              <img
                v-if="item.typeCode == 2"
                src="../../public/images/asset/icon-h-2.png"
                alt=""
              />
              <img
                v-else-if="item.typeCode == 4"
                src="../../public/images/asset/icon-h-3.png"
                alt=""
              />
              <img v-else src="../../public/images/asset/icon-h-1.png" alt="" />
              <div class="content">
                <span>{{ item.type }}</span>
                <span>{{ item.date }}</span>
              </div>
            </div>
            <div class="item-r">
              <span>{{ item.amount }}</span>
              <span>{{ item.status }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="rr-wrap animate__animated animate__bounceInUp" v-else>
        <div class="no-data">
          <img src="../../public/images/no-data.png" alt="" />
          <span>{{ $t("record.desc13") }}</span>
        </div>
      </div>
      <div class="rr-page" v-if="recordList && recordList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <div
        class="rr-bt"
        v-if="detailInfo.withdrawStatus || detailInfo.rechargeStatus"
      >
        <div
          class="rr-bt-item animate__animated animate__bounceInLeft"
          v-if="detailInfo.withdrawStatus"
          @click="jump_link('/withdraw')"
        >
          提现
        </div>
        <div
          class="rr-bt-item animate__animated animate__bounceInRight"
          v-if="detailInfo.rechargeStatus"
          @click="jump_link('/recharge')"
        >
          充值
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="startShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="startCurrentTime"
        type="date"
        @confirm="confirmStartFn()"
        @cancel="startShow = false"
      />
    </van-popup>
    <van-popup
      v-model:show="endShow"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="endCurrentTime"
        type="date"
        @confirm="confirmEndFn()"
        @cancel="endShow = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Clipboard from "@/utils/libs/clipboard";
import { mapState } from "vuex";
export default {
  data() {
    return {
      type: -1,
      typeName: "全部",
      isCoinListLoading: false,
      typeList: [
        {
          name: "全部",
          id: -1,
        },
        {
          name: "充值",
          id: 1,
        },
        {
          name: "提现",
          id: 2,
        },
        {
          name: "兑换",
          id: 4,
        },
      ],
      coinId: "",
      coinName: "",
      coinList: [],
      isRecordListLoading: false,
      recordList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      startTime: "",
      endTime: "",
      value1: "",
      startShow: false,
      endShow: false,
      startCurrentTime: new Date(),
      endCurrentTime: new Date(),
      detailInfo: {},
    };
  },
  computed: {
    ...mapState(["address"]),
  },
  mounted() {
    if (this.$route.query.id) {
      this.coinId = this.$route.query.id;
    }
    this.handleRecordList();
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$post(this.URL.assets.view, {
        coinId: this.coinId,
      }).then((res) => {
        if (res.code == 0) {
          this.detailInfo = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleRecordList() {
      if (this.isRecordListLoading) return;
      this.isRecordListLoading = true;
      let canType = [];
      if (this.type > 0) {
        canType.push(this.type + "");
      } else {
        canType = "";
      }
      this.$post(this.URL.assets.record, {
        coinId: this.coinId,
        types: canType,
        startTime: this.startTime,
        endTime: this.endTime,
        page: this.page,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code == 0) {
          this.isRecordListLoading = false;
          this.recordList = res.data.list;
          this.recordList.forEach((item) => (item.address = this.address));
          this.total = res.data.page.count;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleCurrentChange(num) {
      this.page = num;
      this.handleRecordList();
    },
    dateSelect(arr) {
      this.startTime = this.newDate(arr[0]);
      this.endTime = this.newDate(arr[1]);
      this.page = 1;
      this.handleRecordList();
    },
    confirmStartFn() {
      this.startTime = this.newDate(this.startCurrentTime);
      this.startShow = false;
      if (this.endTime) {
        this.page = 1;
        this.handleRecordList();
      }
    },
    confirmEndFn() {
      this.endTime = this.newDate(this.endCurrentTime);
      this.endShow = false;
      if (this.startTime) {
        this.page = 1;
        this.handleRecordList();
      }
    },
    selectType(id) {
      // 根据类型查询

      this.type = id;
      for (let i = 0; i < this.typeList.length; i++) {
        const element = this.typeList[i];
        if (element.id == id) {
          this.typeName = element.name;
          break;
        }
      }
      this.page = 1;
      this.handleRecordList();
    },
    newDate(time) {
      // 根据时间查询
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    selectCoin(id) {
      // 根据币种查询
      this.coinId = id;
      this.page = 1;
      this.handleRecordList();
    },
    dealAddress(str) {
      if (str && str.length > 10) {
        return (
          str.substring(0, 6) +
          "...." +
          str.substring(str.length - 4, str.length)
        );
      } else {
        return str;
      }
    },
    copy(e, text) {
      // @click="copy($event,googleRandom.secretKey)"
      let that = this;
      Clipboard(e, text).then((res) => {
        that.$message.success(this.$t("planet.desc61"));
      });
    },
    goDetail(item) {
      localStorage.setItem("detailItem", JSON.stringify(item));
      this.$router.push({
        path: "record-detail",
      });
    },
    jump_link(url) {
      this.$router.push({
        path: url,
        query: {
          id: this.coinId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  padding: 68px 16px 72px;
  min-width: 0;
  background: #000;
  width: 100%;
  min-height: 100vh;
  .wrap {
    width: 100%;
  }
  .rr-title {
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Bai Jamjuree";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    color: #ffffff;
    position: relative;
    margin-bottom: 0;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 17px;
      height: auto;
      cursor: pointer;
    }
  }
  .wrap-count {
    padding: 30px 24px 23px;
    background: linear-gradient(
      180deg,
      rgba(255, 226, 127, 0.3) -10.71%,
      rgba(110, 85, 38, 0.3) 84.58%
    );
    margin-bottom: 30px;
    border-radius: 16px;
    animation-delay: 0.1s;
    .num {
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      text-transform: capitalize;
      color: #ffffff;
      margin-bottom: 10px;
    }
    span {
      display: block;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-transform: capitalize;
      color: #ffffff;
      opacity: 0.6;
    }
  }
  .wrap-til {
    padding: 0;
    font-family: "Bai Jamjuree";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    text-transform: capitalize;
    color: #ffffff;
    margin-bottom: 16px;
    animation-delay: 0.2s;
  }
  .rr-h5-time {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
    height: 48px;
    padding: 0 44px 0 10px;
    background-color: rgba(255, 226, 127, 0.3);
    border-radius: 4px;
    &-out {
      display: block;
      padding: 0;
      animation-delay: 0.3s;
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 18px;
    }
    span {
      display: block;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-transform: capitalize;
      color: #ffffff;
      padding: 0 18px;
    }
    input {
      width: 100%;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-transform: capitalize;
      color: #ffffff;
      &::placeholder {
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .rr-tabbar {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 0;
    animation-delay: 0.3s;
    .rr-select {
      flex: 0 0 343px;
      margin-right: 0;
      margin-bottom: 8px;
      ::v-deep .el-select {
        width: 100%;
        height: 48px;
        border: 0;
        .el-input__inner {
          color: #fff;
        }

        .el-input__wrapper {
          height: 48px;
          background-color: rgba(255, 226, 127, 0.3);
          border: none !important;
          box-shadow: none !important;
          box-sizing: border-box;
        }
      }
      .rr-type {
        display: none;
      }
    }
    .rr-select-time {
      display: none;
    }
  }
  .rr-wrap {
    animation-delay: 0.4s;
    .no-data {
      width: 100%;
      padding: 60px 0;

      img {
        display: block;
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }

      span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 10px;
        text-align: center;
        color: #a1a1a1;
      }
    }
    .rr-wrap-pc {
      display: none;
    }
    .rr-wrap-h5 {
      display: block;
      padding: 0;
      .item {
        padding: 16px 13px 16px 0;
        border-bottom: 1px solid rgba(255, 226, 127, 0.3);
        position: relative;
        display: flex;
        justify-content: space-between;
        .detail-go {
          position: absolute;
          top: 50%;
          right: 0;
          width: 6px;
          height: auto;
        }
        .item-l {
          display: flex;
          img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
          }
          .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            span {
              display: block;
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 16px;
              text-transform: capitalize;
              color: #ffffff;
              &:last-child {
                font-family: "Bai Jamjuree";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                text-transform: capitalize;
                color: #bbbbbb;
              }
            }
          }
        }
        .item-r {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          span {
            display: block;
            font-family: "Bai Jamjuree";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            text-transform: capitalize;
            color: #ffffff;
            &:last-child {
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              text-transform: capitalize;
              color: #bbbbbb;
            }
          }
        }
      }
    }
  }
  .rr-page {
    padding: 40px 0;

    ::v-deep .el-pagination {
      button {
        background-color: rgba(255, 255, 255, 0);
        color: #666666;
        font-size: 14px;
      }

      .el-pager {
        li {
          background: rgba(255, 255, 255, 0);
          font-size: 14px;
          color: #666666;
        }

        .is-active {
          background: #ffe279 !important;
          border-radius: 4px !important;
          color: #000000 !important;
        }
      }
    }
  }
  .rr-bt {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    background: #000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Nebula";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      text-transform: capitalize;
      color: #ffffff;
      width: 162px;
      height: 54px;
      background: url("../../public/images/asset/asset-bt1.png") no-repeat;
      background-size: 100%;
      &:last-child {
        color: #000;
        background: url("../../public/images/asset/asset-bt2.png") no-repeat;
        background-size: 100%;
      }
    }
  }
  ::v-deep .van-popup {
    background: #000;
    .van-picker {
      background: linear-gradient(
        180deg,
        rgba(255, 226, 127, 0.2) -10.71%,
        rgba(110, 85, 38, 0.2) 84.58%
      );
      .van-picker__toolbar {
        background: rgba(255, 226, 127, 0.3);
        .van-picker__cancel {
          color: #fff;
        }
        .van-picker__confirm {
          color: #ffe27f;
        }
      }
      .van-picker__columns {
        .van-picker__mask {
          display: none;
        }
      }
      .van-picker-column__item {
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
      }
      .van-hairline--top-bottom:after,
      .van-hairline-unset--top-bottom:after {
        border-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>
